<template>
  <div
    class="data-manager-wrap container"
    style="font-size: 16px; border-radius: 5px"
  >
    <div style="background: #fff; border-radius: 5px">
      <div class="data-manager-header">
        <div class="d-inline-block">
          <img src="@/assets/img/head/icon.png" style="margin-bottom: 5px" />
          <b style="font-size: 20px">非上市公司</b>
        </div>
        <div class="left" style="padding: 13px">
          <el-button
            size="medium"
            v-if="!isshow"
            @click="showAddDialog = true"
            type="primary"
            >新增企业</el-button
          >
        </div>
      </div>
      <div
        v-if="activeTabIndex === 0"
        style="padding-left: 20px; padding-right: 20px"
      >
        <div class="data_header">
          <div class="in-header">
            <div class="row align-items-center">
              <span>企业名称：</span>
              <el-input
                v-model="params.company_name"
                placeholder="请输入企业名称"
                size="small"
                style="width: 200px"
                maxlength="50"
              ></el-input>
            </div>
            <div class="row align-items-center ml-3">
              <span>企业性质：</span>
              <el-select
                style="width: 200px"
                v-model="params.company_type"
                size="small"
              >
                <el-option :value="''" label="所有"></el-option>
                <!-- <el-option :value="0" label="一般企业"></el-option>
                <el-option :value="1" label="小微企业"></el-option>
                <el-option :value="3" label="事业单位"></el-option> -->
              </el-select>
            </div>
            <div class="row align-items-center ml-3">
              <span>创建时间：</span>
              <el-date-picker
                style="width: 300px"
                v-model="value1"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                size="small"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </div>
          </div>
          <div class="btnSearch">
            <el-button
              style="font-size: 14px"
              icon="el-icon-search"
              size="small"
              class="search"
              @click="queryMyNewCompanys('query')"
            >
              查询
            </el-button>
            <el-button
              style="font-size: 14px"
              icon="el-icon-refresh-right"
              size="small"
              class="reset"
              @click="queryMyNewCompanys('reset')"
            >
              重置
            </el-button>
          </div>
        </div>
      </div>
    </div>
    <!-- 以上是新增企业头部 -->
    <div id="tabs" class="tabs" ref="tabs">
      <div class="tab active" @click="onTab(0)" ref="fthTab">我的企业</div>
      <div class="tab" @click="onTab(1)">新建企业</div>
      <div class="bar" ref="bar"></div>
    </div>
    <div v-if="activeTabIndex === 0" class="table_box">
      <div class="table_cen">
        <el-table
          stripe
          ref="table_cen"
          :data="myData"
          style="width: 100%; color: #333; font-size: 14px"
          :maxHeight="tableContentHeight"
          :minHeight="200"
          :default-sort="{ prop: 'create_time', order: 'descending' }"
          :header-cell-style="{
            background: '#E9F3FF',
            color: '#333',
            height: '48px',
          }"
        >
          <!-- <el-table-column width="40" align="right">
            <template slot-scope="{ row }">
              <i @click="$refs.table_cen.toggleRowExpansion(
                  row,
                  !row.select,
                  (row.select = !row.select)
                )
                " style="cursor: pointer; color: #1172fe;" 
                :class="row.select ? 'el-icon-caret-bottom' : 'el-icon-caret-right'"></i>
            </template>
          </el-table-column> -->
          <el-table-column
            type="index"
            width="100"
            label="序号"
            align="center"
          ></el-table-column>
          <el-table-column prop="name" label="公司名称">
            <div
              style="
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              "
              slot-scope="{ row }"
            >
              {{ row.name }}
            </div>
          </el-table-column>
          <el-table-column prop="company_type" label="企业性质">
            <div slot-scope="{ row }">
              {{ company_type[row.company_type] }}
            </div>
          </el-table-column>
          <el-table-column
            prop="create_time"
            label="创建时间"
            sortable
          ></el-table-column>
          <el-table-column label="操作" style="font-size: 16px" width="300">
            <template slot-scope="scope">
              <!-- <i class="see_report" @click="onCompany(scope.row)">分析入口</i
              ><span class="sc">|</span> -->
              <i class="see_report" @click="onLink(scope.row)">报表管理</i
              ><span class="sc">|</span>
              <i class="see_report" @click="ondelete(scope.row)">删除</i>
            </template>
            <!-- <template slot-scope="scope">
              <i class="see_report" @click="onUpload(scope.row)">上传报表</i><span class="sc">|</span>
              <i class="see_report" @click="onCompany(scope.row)">查看结果</i><span class="sc">|</span>
              <el-popover placement="bottom" trigger="click">
                <p style="text-align: center">
                  <i class="see_report" @click="onEditCompany(scope.row)">编辑</i>
                </p>
                <p style="text-align: center">
                  <i class="see_report" @click="ondelete(scope.row)">删除</i>
                </p>
                <i class="see_report" slot="reference">更多操作</i>
              </el-popover>
            </template> -->
          </el-table-column>

          <!-- <el-table-column type="expand" width="100%">
            <template slot-scope="props">
              <p style=" margin-left: 600px" v-for="(item, key) in props.row.year_list" :key="item">
                <span style="margin-right: 50px">{{ key }}</span>
                <s-mounth style="margin-right: 5px" v-for="(v, index) in props.row.year_list[key]" :key="index" :type="+v"
                  :content="index + 1"></s-mounth>
              </p>
            </template>
          </el-table-column> -->
        </el-table>
      </div>

      <div class="text-center">
        <v-pagination
          ref="pagination"
          :total="myNewCompanysTotal"
          :pageSize="pageSize"
          :pageIndex="pageNum"
          @page-change="myDataNextPage"
          @page-size-change="pageSizeChange"
          size="middle"
        ></v-pagination>
      </div>
    </div>
    <div v-if="activeTabIndex === 1" class="cen_box">
      <div v-if="fillStep === 2">
        <fill-company-step-two
          ref="2"
          :companyCode="companyCode"
          :companyName="companyName"
          @sensitive="sensitiveRemind"
          :isAct="false"
        ></fill-company-step-two>
        <div class="bts" style="padding-left: 45%; margin-top: 20px">
          <!-- <el-button @click="onNext(-1)">上一步</el-button> -->
          <el-button @click="onTab(0)">返回</el-button>
          <el-button
            type="primary"
            :disabled="disabledSave"
            @click="onNextTwo(1)"
            >保存</el-button
          >
        </div>
      </div>
      <div v-else-if="fillStep === 3">
        <fill-company-step-three
          :companyCode="companyCode"
          :companyName="companyName"
          ref="3"
        ></fill-company-step-three>
        <div class="bts" style="margin-top: 20px; text-align: center">
          <el-button @click="onNext(-1)">上一步</el-button>
          <el-button type="primary" @click="onSaveTable">提交数据</el-button>
        </div>
      </div>
    </div>
    <!-- 编辑 -->
    <el-dialog
      title="编辑公司"
      v-if="showEditDialog"
      :visible.sync="showEditDialog"
      width="30%"
    >
      <fill-company-step-one
        v-if="showEditDialog"
        :companyName.sync="companyName"
        :companyCode="companyCode"
        :indusCode.sync="indusCode"
        :industry_name.sync="industry_name"
        :isAct="false"
        ref="edit"
        :flag="1"
      ></fill-company-step-one>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="showEditDialog = false">取消</el-button> -->
        <el-button style="width: 50%" type="primary" @click="onSaveEdit"
          >保存</el-button
        >
      </span>
    </el-dialog>
    <!-- 新增 -->
    <el-dialog
      title="新增企业"
      :visible.sync="showAddDialog"
      v-if="showAddDialog"
      width="500px"
    >
      <fill-company-step-one
        v-if="showAddDialog"
        :flag="0"
        :companyCode="companyCode"
        :isAct="true"
        ref="1"
      ></fill-company-step-one>

      <span slot="footer" class="dialog-footer">
        <el-button @click="showAddDialog = false">取消</el-button>
        <el-button @click="onSave" type="primary">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import qs from "qs";
import FillCompanyStepOne from "@/components/FillCompanyStepOne";
import FillCompanyStepTwo from "@/components/FillCompanyStepTwo";
import FillCompanyStepThree from "@/components/FillCompanyStepThree";
import FillCompany from "@/components/FillCompany";
// import { qcc_CompanyInfo } from "@/api/companyDetail.js";
import {
  myNewCompanys,
  excelHandle,
  tjINdustryList,
  removecompany,
  yearList,
} from "@/api";
import * as sesstion from "@/store/localStorage";
import loadingVue from "../../components/loading/loading.vue";
const VM = require("@/components/VM.js").default;
export default {
  name: "dataMager",
  components: {
    FillCompanyStepOne,
    FillCompanyStepTwo,
    FillCompanyStepThree,
    FillCompany,
    SMounth: require("@/components/DataManager/Month.vue").default,
  },
  data() {
    return {
      mounth: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      company_type: [
        "一般企业",
        "小微企业",
        "事业单位",
        "事业单位",
        "事业单位",
        "事业单位",
      ],
      value1: "",
      params: {
        start_time: "",
        end_time: "",
        company_name: "",
        company_type: "",
      },
      yearList: ["2020年", "2019年", "2018年", "2017年", "2016年"],
      showAddDialog: false,
      check: 0,
      disabledSave: false,
      companyName: "",
      companyCode: "",
      indusCode: "",
      industry_name: "",
      activeTabIndex: 0,
      fillStep: 1,
      barWidth: 50,
      myData: [],
      pageNum: 1,
      pageSize: 10,
      obj: {},
      myNewCompanysTotal: 0,
      // showUploadDialog: false,
      isDirectUploadSheet: false,
      showEditDialog: false,
      isshow: 0,
      ismycompany: "非上市公司",
      companyUP: {},
      // tableContentHeight: window.innerHeight - 181 - 60 - 50 - 51 - 59,
      tableContentHeight: window.innerHeight,
      TJ: "",
      CSRC: "",
      SWAI: "",
    };
  },
  mounted() {
    if (this.$route.path == "/CenterIndex/data-manager") {
      this.$router.push("/database/data-manager");
    } else {
      this.isjump();

      VM.DataManager = this;
      this.$refs.bar.style.width = this.$refs.fthTab.clientWidth + "px";
      this.$store.commit("setListingParame", {
        name: "report_type",
        value: "",
      });
      this.$store.commit("setListingParame", {
        name: "report_time",
        value: "",
      });
      this.$store.commit("setListingParame", {
        name: "selectedYear",
        value: 0,
      });
      this.myDataNextPage(1);
    }
  },
  created() {
    window.onresize = this.setHeight.bind(this);
  },
  methods: {
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    setHeight() {
      // this.tableContentHeight = window.innerHeight - 180 - 60 - 50 - 51 - 59;
      this.tableContentHeight = window.innerHeight;
    },
    isjump() {
      if (this.$route.query.addjump == 1) {
        this.onTab(1);
      } else {
        this.onTab(0);
      }
    },

    onLink(company) {
      sesstion.setLocalData("company", company);
      this.$router.push("/reportManagement");
    },
    // 报表上传
    onUpload(company) {
      // if (company.company_type >= 3) return this.$Message.warning("敬请期待");
      if (company.company_type == 1) return;
      tjINdustryList({
        statistics_code: company.statistics_code,
        CSRC_code: company.CSRC_code,
      }).then((res) => {
        let industry_name = "";
        const { Result } = res.data;

        industry_name = industry_name.substr(0, industry_name.length - 1);
        sessionStorage.company_code = company.company_code;

        this.companyUP = company;
        sessionStorage.industryData = JSON.stringify(res.data);

        // this.indusCode = company.statistics_code;
        this.indusCode = company.CSRC_code;
        this.industry_name = industry_name;
        sessionStorage.companyName = company.name;
        setTimeout(() => {
          this.$router.push({
            name: "upReport",
            query: {
              company_type: company.company_type,
            },
          });
        }, 200);
      });
    },
    // 删除
    ondelete(company) {
      // if (company.company_type >= 3) return this.$Message.warning("敬请期待");
      if (company.company_type == 1) return;
      this.$confirm("确认删除该企业吗？", "温馨提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        dangerouslyUseHTMLString: true,
        customClass: "submitConfirm",
        // type: "warning",
      })
        .then(() => {
          removecompany(company.company_code)
            .then((e) => {
              this.queryMyNewCompanys();
            })
            .catch((err) => {
              this.$Message.warning("删除失败");
            });
          // this.submit();
        })
        .catch(() => {
          //  alert(3)
        });
    },
    check() {},
    jumpPage(item) {
      sesstion.setLocalData("company", item);
      if (item.company_type == 1)
        return this.$router.push({
          name: "smallCompany",
          query: { name: item.name },
        });
      if (item.company_type >= 3)
        return this.$router.push({
          // name: "causeCompany",
          name: "CompanyDetail",
          query: { name: item.name },
        });

      this.$router.push({ name: "CompanyDetail", query: { name: item.name } });
    },
    onCompany(item) {
      // if (item.company_type == 1) return this.$Message.warning("敬请期待");
      // qcc_CompanyInfo({ company_name: item.name }).then(({ data }) => {
      //   sesstion.setLocalData("company_name", data);
      // });
      //  rating({
      //     year: param.year,

      //     report_time: param.report_time,
      //     company_name: param.name || param.company_name,
      //     company_code: param.company_code,
      //     safety_value: this.safety_watch,
      //     truth_value: this.gaugeName,
      //   }).then((res) => {
      //     console.log(res);
      //   }),
      // 请求分析数据
      let param = {
        company_code: item.company_code,
        // menu_name: "simple" ,
        report_time: "",
        report_type: "",
        report_year: "",
      };
      sesstion.setLocalData("rowList", param);
      yearList(param).then(({ data }) => {
        // console.log(data);
        if (data.Status == 0) {
          this.$message.error(data.Message);
        } else if (data.Status == 1) {
          this.$confirm(data.Message, "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              sessionStorage.back_name = this.$route.name;
              this.jumpPage(item);
            })
            .catch(() => {});
        } else {
          sessionStorage.back_name = this.$route.name;
          this.jumpPage(item);
        }
      });
    },
    onEditCompany(company) {
      // if (company.company_type >= 3) return this.$Message.warning("敬请期待");
      // console.log(company);
      if (company.company_type == 1) return;
      // 调用后台接口获取行业数据
      tjINdustryList({
        statistics_code: company.statistics_code || "",
        CSRC_code: company.CSRC_code || "",
        Industry_code: company.SWAI_code || "",
      }).then((res) => {
        // console.log(res);
        let industry_name = "";
        const Result = res.data;
        // console.log(Result);
        var arr = [
          "industry_level_one",
          "industry_level_two",
          "industry_level_three",
          "industry_level_four",
          "industry_level_five",
        ];
        for (let index = 0; index < arr.length; index++) {
          const key = arr[index];
          if (!Result[key]) break;
          industry_name += Result[key].industry_name + "/";
        }

        industry_name = industry_name.substr(0, industry_name.length - 1);
        this.companyName = company.name;
        this.companyCode = company.company_code;
        // this.indusCode = company.statistics_code;
        this.indusCode = company.CSRC_code;
        this.industry_name = industry_name;
        sessionStorage.industryData = JSON.stringify(res.data);
        this.$nextTick(() => {
          setTimeout(() => {
            this.$VM.FillCompanyStepOne.setSubjectNature(company.company_type);
          }, 0);
          // 打开编辑页面
          this.showEditDialog = true;
        });
      });

      // this.onTab(1)
    },

    // 取消
    onCancelEdit() {
      this.companyName = "";
      this.indusCode = "";
      this.showEditDialog = false;
    },
    // 取消上传
    onCancelUpload() {
      this.companyName = "";
      this.indusCode = "";
      this.showUploadDialog = false;
    },
    sensitiveRemind(res) {
      // console.log(res);
      this.fillStep = res;
      // console.log(this.fillStep);
    },

    pageSizeChange(newPageSize) {
      this.pageSize = newPageSize;
      this.pageNum = 1;
      this.myDataNextPage(this.pageNum);
    },
    myDataNextPage(pageNum) {
      this.pageNum = pageNum;
      this.queryMyNewCompanys();
    },
    queryMyNewCompanys(handle) {
      this.$loadingShow();
      if (handle == "reset") {
        this.value1 = "";
        this.params.start_time = "";
        this.params.end_time = "";
        this.params.company_name = "";
        this.params.company_type = "";
        this.pageNum = 1;
        this.$refs.pagination.newPageIndex = 1;
      }
      if (handle == "query") {
        this.pageNum = 1;
        this.$refs.pagination.newPageIndex = 1;
      }
      if (this.value1) {
        this.params.start_time = this.value1[0] + " 00:00:00";
        this.params.end_time = this.value1[1] + " 23:59:29";
      }

      myNewCompanys(
        { pagesize: this.pageSize, pagenumber: this.pageNum },
        qs.stringify(this.params)
      )
        .then(({ data }) => {
          data.companyList.map((item) => {
            !item.year_list &&
              (item.year_list = {
                2020: [
                  true,
                  false,
                  true,
                  false,
                  true,
                  false,
                  true,
                  false,
                  true,
                  false,
                  true,
                  false,
                  true,
                  false,
                  true,
                  false,
                ],
                2019: [
                  true,
                  false,
                  true,
                  false,
                  true,
                  false,
                  true,
                  false,
                  true,
                  false,
                  true,
                  false,
                  true,
                  false,
                  true,
                  false,
                ],
              });
          });
          this.myNewCompanysTotal = data.total;
          this.myData = data.companyList;
          this.$loadingHide();
        })
        .catch((err) => {
          console.error("myNewCompanys", err);
          this.$loadingHide();
        });
    },
    onSaveUpload() {
      //没有加载 FillCompanyDataTable 组件 则有问题
      // console.log(this.$refs.upload.saveData(res))

      this.$refs.upload
        .saveData()
        .then((res) => {})
        .catch((err) => {
          console.error(err);
          this.$tip({
            type: "error",
            text: err.Message || err.Msg || err,
          });
          this.$loadingHide();
        });
    },
    refresh() {
      this.queryMyNewCompanys();
    },
    onSaveEdit() {
      this.$loadingShow();
      this.$refs.edit
        .saveData()
        .then(() => {
          this.companyName = "";
          this.indusCode = "";
          this.showEditDialog = false;
          this.refresh();
          this.$loadingHide();
        })
        .catch((err) => {
          // this.companyName = ''
          // this.indusCode = ''
          // this.onTab(0)
          console.error(err);
          this.$loadingHide();
        });
    },
    onSave() {
      this.$refs[this.fillStep].saveData().then((companyCode) => {
        if (!companyCode) {
          return this.$message.error(companyCode);
        } else {
          this.$message.success("创建成功！");

          this.companyName = "";
          this.indusCode = "";
          this.fillStep = 1;
          this.myDataNextPage(1);
          this.onTab(0);
          this.showAddDialog = false;
        }
      });
    },

    // open() {
    //   this.$confirm("请确认是否继续提交?", "提示", {
    //     confirmButtonText: "提交",
    //     cancelButtonText: "继续填写",
    //     type: "warning",
    //   })
    //     .then(() => {

    //     })
    //     .catch(() => {});
    // },
    openCheck(msg) {
      this.$confirm(msg, "提示", {
        confirmButtonText: "提交",
        cancelButtonText: "继续填写",
        dangerouslyUseHTMLString: true,
        customClass: "submitConfirm",
        // type: "warning",
      })
        .then(() => {
          this.submit();
        })
        .catch(() => {});
    },
    // 提交数据
    checkData() {
      let params = this.obj;
      params.company_code = sessionStorage.company_code;

      // console.log(params);
      var config = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          // "Content-Type": "application/json",
          Authorization:
            "Bearer " + this.$store.state.isLogin.loginStore.auth_token,
        },
      };
      this.$axios
        .post(`${window.BASE_URL}/upload/multiple-fs/check/`, params, config)
        .then(({ data }) => {
          if (data.check_status == false) {
            let arr = [];
            data.message.forEach((v) => {
              arr.push(v);
            });
            var str = "";
            arr.forEach((v, i) => {
              if (i == arr.length - 1) {
                str = str + "<div style='padding-top:20px'>" + v + "</div>";
              } else {
                str = str + "<div>" + v + "</div>";
              }
            });
            this.openCheck(str);
          } else if (data.check_status == true) {
            this.submit();
          }
        })
        .catch((err) => {
          this.$Message.error(err);
        });
    },
    submit() {
      let params = this.obj;
      // console.log(params);
      var config = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          // "Content-Type": "application/json",
          Authorization:
            "Bearer " + this.$store.state.isLogin.loginStore.auth_token,
        },
      };
      this.$axios
        .post(`${window.BASE_URL}/upload/multiple-fs/save/`, params, config)
        .then(({ data }) => {
          // console.log(data);
          sesstion.removeLocalData("FZZCTableData");
          sesstion.removeLocalData("LRTableData");
          sesstion.removeLocalData("XJLLTableData");
          if (data.message == "报表保存成功") {
            // sessionStorage.setItem("company", JSON.stringify(this.companyUP));
            // this.$router.push({ name: "CompanyDetail" });
            this.$message({
              type: "success",
              message: data.message,
            });
            this.onTab(0);
          } else {
            this.$message({
              type: "success",
              message: data.message,
            });
          }
        })
        .catch((err) => {
          console.error(err);
          // this.isSave = "";
        });
    },
    onNextTwo(i) {
      this.$loadingHide();
      this.$refs[this.fillStep]
        .saveData()
        .then((resp) => {
          switch (this.fillStep) {
            case 1:
              if (!resp) {
                this.$Message.error("recive companyCode: " + resp);
                return;
              }
              this.companyCode = resp;

              this.$loadingHide();

              break;
            case 2:
              this.fillStep += i;
              // console.log("000", this.fillStep);
              break;
          }
          $(".buy.active.a_box span").click();
          this.$loadingHide();
        })
        .catch((err) => {
          console.error("onNext", err);
          this.$loadingHide();
          this.$tip({
            text: "保存失败，失败原因：" + JSON.stringify(err),
          });
        });
    },
    onNext(i) {
      if (i > 0) {
        // this.$loadingShow()
        this.$refs[this.fillStep]
          .saveData()
          .then((resp) => {
            switch (this.fillStep) {
              case 1:
                if (!resp) {
                  this.$Message.error("recive companyCode: " + resp);
                  return;
                }
                this.companyCode = resp;
                this.fillStep += i;
                this.onTab(0);

                this.$loadingHide();
                break;
              case 2:
                this.fillStep += i;
                // console.log("000", this.fillStep);
                break;
            }
          })
          .catch((err) => {
            console.error("onNext", err);
            this.$loadingHide();
            this.$tip({
              text: "保存失败，失败原因：" + JSON.stringify(err),
            });
          });
      } else {
        if (this.isDirectUploadSheet) {
          this.onTab(0);
          return;
        }
        this.fillStep += i;
      }
    },
    onTab(index) {
      this.isshow = index;
      if (index == 0) {
        this.ismycompany = "企业管理";
      } else {
        this.ismycompany = "新增企业";
      }
      var len = this.$refs.tabs.children.length;
      while (len--) {
        if (this.$refs.tabs.children[len].className.indexOf("tab") > -1) {
          this.$refs.tabs.children[len].className = "tab";
        }
      }
      this.$refs.tabs.children[index].className = "tab active";
      this.$refs.bar.style.left =
        this.$refs.tabs.children[index].offsetLeft + "px";
      this.activeTabIndex = index;
      if (index === 0) {
        this.companyName = "";
        this.companyCode = "";
        this.isDirectUploadSheet = false;
        this.fillStep = 1;
      }
    },
  },
};
</script>
<style lang="less" scoped>
@media (min-width: 1200px) {
  .container {
    max-width: 1840px;
  }
}

.dialog-footer {
  display: flex !important;
  justify-content: center;

  .el-button {
    width: 100px;
  }
}

.submitConfirm {
  width: 460px;
}

.el-tag {
  background: #1989fa;
  color: #fff;
}

.el-tag.el-tag--info.el-tag--small.el-tag--light {
  background-color: #fff;
  border-color: #e9e9eb;
  color: #909399;
}

.btn-addCompany-my {
  width: 92px;
  height: 36px;
  line-height: 36px;
  color: #1270ff !important;
  border: 1px solid #1270ff;
  text-align: center;
}

.left {
  float: right;
}

.cen_box {
  background: #fff;
  margin-top: -40px;
  padding-bottom: 20px;
}

#tabs {
  opacity: 0;
  position: absolute;
  display: none;
}

.data-manager-wrap {
  // background: #ffffff;
  // padding: 1.5rem 15px;
  // border-radius: 5px;
  // overflow: hidden;
  // box-shadow: #dcdcdd 0px 0px 7px;
  position: relative;
  color: #444;

  .data-manager-header {
    width: 100%;
    padding-left: 20px;
    padding-right: 40px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #f0f0f0;
    // background: #ffffff;
    display: flex;
    align-items: center;

    .d-inline-block {
      font-size: 20px !important;
      font-weight: 600px !important;
      color: #333;

      img {
        margin-right: 10px;
      }
    }
  }

  .data_header {
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 14px;
    justify-content: space-between;
    padding: 20px;

    .in-header {
      display: flex;
      font-size: 14px;
      align-items: center;
    }
    .btnSearch {
      .reset {
        background: #ffffff;
        color: #1989fa;
        margin-right: 5px;
      }

      .search {
        background: #1989fa;
        color: #fff;
      }
    }
  }
}

.tabs {
  width: 100%;
  border-bottom: 2px solid #e0e4ea;
  padding-bottom: 10px;
  display: flex;
  position: relative;

  .tab {
    cursor: pointer;
    padding: 10px;
  }

  .bar {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 4px;
    background: #4a81fd;
    transition: all ease 0.3s;
  }

  .active {
    color: #4a81fd;
  }
}

.icon-bianji {
  margin-right: 20px;
}

.table_box {
  padding-left: 20px;
  padding-right: 20px;
  background: #fff;

  .table_cen {
    margin-bottom: 20px;
    padding: 0 20px;

    /deep/.el-table__expand-icon {
      display: none;
    }

    /deep/tr th:nth-child(1) {
      width: 10px;
    }

    /deep/ tr td:nth-child(1) {
      width: 10px;
    }
  }

  .text-center {
    padding-bottom: 20px;
    padding-right: 30px;
    text-align: right !important;
  }
}

/deep/.el-range-separator {
  width: 27px;
}

.edit-company-dialog {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.3);

  .content {
    background: #ffffff;
    padding: 30px;
    border-radius: 5px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.see_report {
  cursor: pointer;
  font-size: 14px;
  color: #1172fe;
  padding: 0 5px;
}

.sc {
  color: #d2d2d3;
}
</style>
