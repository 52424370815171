<template>
  <div class="box">
    <div class="result">
      <div v-for="(val,key,i) in dataList" :key="i">
        <p class="result_p">{{key}}</p>
        <div class="desc" v-for="arry in dataList[key]" :key="arry">
          <p v-html="arry">{{arry}}</p>
        </div>
      </div>
    </div>
    <!-- <span class="btn">返回修改表格内容</span> -->
  </div>
</template>


<script>
import * as sesstion from "../store/localStorage";
export default {
  props: {
    companyName: String,
    companyCode: String,
  },
  data() {
    return {
      dataList: [],
    };
  },
  mounted() {
    const data = sesstion.getLocalData("filedata");
    this.dataList = data.Data;
  },
  computed: {},
  methods: {},
};
</script>

<style lang="less" scoped>
.box {
  width: 100%;
  margin: 0 auto;
  text-align: center;
}
.result {
  width: 500px;
  padding: 15px;
  text-align: left;
  .result_p {
    font-size: 18px;
    color: #2b85e4;
  }
  .desc p{
    text-indent: 28px;
    font-size: 14px;
    line-height: 25px;
  }
}
</style>
