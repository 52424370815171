<template>
  <div>
    <table cellpadding="8" cellspacing="8">
      <tr>
        <td>
          <div class="left">企业名称</div>
        </td>
        <td>
          <div class="right">
            <el-input
              suffix-icon="el-icon-search"
              placeholder="企业名称，50个字符内"
              v-model="selfCompanyName"
              maxlength="50"
              size="small"
            >
            </el-input>
          </div>
        </td>
      </tr>
      <tr>
        <td>
          <div class="left">企业性质</div>
        </td>
        <td>
          <div class="right">
            <RadioGroup
              v-if="flag == 0"
              v-model="subjectNature"
              @on-change="onChange"
            >
              <Radio
                v-for="(company, i) in subjectNatureArr"
                :key="company"
                :label="company"
                :disabled="i == 1"
              ></Radio>
            </RadioGroup>
          </div>
        </td>
      </tr>
      <tr>
        <td>
          <div class="left">所在行业</div>
        </td>
        <td>
          <div class="right">
            <div style="width: 100%">
              <div style="width: 100%">
                <selectTree
                  ref="selectTreegb"
                  placeholder="请选择企业所在行业"
                  :isActive="isAct"
                  :isGb="true"
                  :defaultIndeusName="industry_name"
                  :defaultIndusCode="indusCode"
                  :subjectNature="subjectNature"
                />
              </div>
            </div>
          </div>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import * as sesstion from "../store/localStorage";
import { queryIndus, newCompany, editCompany } from "@/api";
import { get_company_list } from "@/api/dataManager";
export default {
  props: {
    companyName: String,
    companyCode: String,
    indusCode: String,
    isAct: Boolean,
    industry_name: String,
    flag: String,
  },
  components: {
    selectTree: require("@/components/selectTree/selectTree1.vue").default,
  },
  data() {
    return {
      loading: false,
      options: [],
      selfCompanyName: "",
      // 是否编辑
      isEditCompany: false,
      firstTJIndus: [],
      firstTJSelectedCode: "",
      // fileType: "",
      // fileTypeList: [],
      secTJIndus: [],
      secTJSelectedCode: "",
      thirdTJIndus: [],
      thirdTJSelectedCode: "",
      subjectNature: "一般企业",
      subjectNatureArr: ["一般企业"],
      schoolsOrHospitals: "",
    };
  },
  computed: {
    selectedIndusCode() {
      var result =
        this.thirdTJSelectedCode === ""
          ? this.secTJSelectedCode === ""
            ? this.firstTJSelectedCode
            : this.secTJSelectedCode
          : this.thirdTJSelectedCode;
      this.$emit("update:indusCode", result);
      return result;
    },
  },
  watch: {
    selfCompanyName() {
      this.$emit("update:companyName", this.companyName);
    },
    firstTJSelectedCode() {
      this.secTJIndus = [];
      this.thirdTJIndus = [];
      this.queryIndusList("gb", this.firstTJSelectedCode).then((data) => {
        this.secTJIndus = data;
      });
    },
    secTJSelectedCode() {
      this.thirdTJIndus = [];
      this.queryIndusList("gb", this.secTJSelectedCode).then((data) => {
        this.thirdTJIndus = data;
      });
    },
    // fileType(val) {
    //   // Bus.$emit("filetype", "val");
    //   // console.log(Bus);
    //   console.log(val);
    //   sesstion.setLocalData("filetype", val);
    // },
  },

  mounted() {
    this.$VM.FillCompanyStepOne = this;
    this.selfCompanyName = this.companyName;

    if (this.companyName) this.isEditCompany = true;
    else this.isEditCompany = false;

    // queryIndus({
    //   Industry_type: "gb",
    // }).then(({ data }) => {
    //   this.firstTJIndus = data.Result;
    // });
    // getfileType({
    //   headers: {
    //     Authorization:
    //       'Bearer ' + this.$store.state.isLogin.loginStore.auth_token,
    //   },
    // }).then(({ data }) => {
    //   // console.log(data)
    //   if (data.Status == "1") {
    //     this.fileTypeList = data.Data;
    //   }
    // });
  },
  methods: {
    onChange() {
      this.schoolsOrHospitals = "";
    },
    querySearchAsync(queryString, cb) {
      if (!queryString || queryString.length < 4) {
        return cb([]);
      }

      if (this.timeout) {
        this.timeout = 0;
        clearTimeout(this.timeout);
      }

      this.timeout = setTimeout(() => {
        get_company_list({ company_name: queryString, search_type: 1 }).then(
          ({ data }) => {
            cb(data.result);
          }
        );
      }, 0.5 * 1000);
    },
    //将其他数据自动补全，采用覆盖的方法
    handleSelect(item) {
      this.selfCompanyName = item.value;
    },
    queryIndusList(industryType, paraentCode = "") {
      return queryIndus({
        Industry_type: industryType,
        Parent_code: paraentCode,
      }).then(({ data }) => {
        return data.Result;
      });
    },
    setSubjectNature(company_type) {
      company_type = company_type || "0";
      if (company_type >= "3") {
        this.subjectNature = this.subjectNatureArr[2];
        this.schoolsOrHospitals = company_type;
      } else {
        this.subjectNature = this.subjectNatureArr[company_type];
      }
    },
    saveData() {
      return new Promise((resolve, reject) => {
        if (!this.selfCompanyName) {
          this.$Message.error("请输入公司名称");
          // this.$tip({
          //   text: "请输入公司名称",
          // });
          // reject(Error("请输入公司名称"));
          this.$loadingHide();
          return;
        }
        if (this.selfCompanyName && this.selfCompanyName.length > 50) {
          // this.$tip({
          //   text: "公司名称过长",
          // });
          this.$Message.error("公司名称过长");

          this.$loadingHide();
          // reject(Error("公司名称过长"));
          return;
        }

        // const industry_code = this.$refs["selectTreetj"].get_industry_code();
        const cause_industry_code = {
          3: "xx",
          4: "yy",
          5: "qt",
        };
        let industry_code = "";

        if (this.$refs["selectTreegb"]) {
          industry_code = this.$refs["selectTreegb"].get_industry_code();
          // console.log(industry_code);
        } else {
          industry_code = cause_industry_code[this.schoolsOrHospitals];
        }
        // console.log(industry_code);
        if (industry_code) {
        } else {
          return this.$Message.error("请选择企业所在行业");
        }
        // if (this.subjectNature === "事业单位") {
        //   console.log(industry_code);
        //   if (industry_code == ""||undefined) {
        //     console.log(12345678);
        //     this.$Message.error("请选择行业");
        //     this.$loadingHide();
        //     // this.$tip({
        //     //   text: "请选择行业",
        //     // });
        //     // reject(Error("请选择行业"));
        //     return;
        //   }
        // }

        const company_type =
          this.subjectNature == "事业单位"
            ? this.schoolsOrHospitals
            : this.subjectNatureArr.indexOf(this.subjectNature);

        // if(this.subjectNature == '事业单位'){
        //   industry_code = undefined
        // }

        if (this.isEditCompany && this.companyCode !== "") {
          // 编辑接口
          editCompany({
            company_code: this.companyCode,
            company_name: this.selfCompanyName,
            company_type: String(company_type),
            CSRC_code: industry_code,
          }).then(({ data }) => {
            if (data.Status === "1") {
              resolve(this.companyCode);
            } else {
              this.$Message.warning(data.Message);
              reject(data.Message);
            }
          });
        } else {
          const huidata = {
            companyName: this.selfCompanyName,
            indusCode: industry_code,
            company_type: String(company_type),
          };
          newCompany(huidata).then(({ data }) => {
            if (data.Status === "1") {
              resolve(data.Company_code);
              sesstion.setLocalData("creatData", huidata);
            } else {
              this.$Message.warning(data.Message);
              reject(data.Message);
            }
          });
          // 校验企业时候重复  此版本弃用校验
          // 新增接口
          // createcompany({
          //   company_name: huidata.companyName,
          //   company_type: huidata.company_type,
          //   industry_code: huidata.indusCode,
          // }).then((res) => {
          //   const createcompanyData = res.data;

          //   if (createcompanyData.Status == 2) {
          //     this.$confirm(
          //       res.data.Message,
          //       "提示",
          //       {
          //         confirmButtonText: "确认",
          //         cancelButtonText: "取消",
          //       }
          //     ).then(() => {
          //       newCompany(huidata).then(({ data }) => {
          //         if (data.Status === "1") {
          //           resolve(data.Company_code);
          //           sesstion.setLocalData("creatData", huidata);
          //         } else {
          //           this.$Message.warning(data.Message);
          //           reject(data.Message);
          //         }
          //       });
          //     });
          //   } else if (createcompanyData.Status == 1) {
          //     this.$Message.warning(createcompanyData.Message);
          //     resolve(createcompanyData.Company_code);
          //   } else {
          //     newCompany(huidata).then(({ data }) => {
          //       // console.log(data);
          //       if (data.Status === "1") {
          //         resolve(data.Company_code);
          //         sesstion.setLocalData("creatData", huidata);
          //       } else {
          //         this.$Message.warning(data.Message);
          //         // reject(data.Message);
          //       }
          //     });
          //   }
          // });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.row {
  display: flex;
  justify-content: space-between;
  padding: 20px 100px;
}

.company-name {
  // border: 1px solid #dcdee2;
}

.select {
  width: 270px;
}

table {
  // margin: 40px auto;
}

/deep/.el-autocomplete-suggestion li {
  font-size: 14px;
}

.left {
  // width: 170px;
  padding: 10px;
  text-align: right;
}

.right {
  width: 350px;
  padding: 10px;
  display: flex;
  align-items: center;
}

@media (max-width: 460px) {
  .select {
    width: 158px;
  }

  .left {
    width: 130px;
    font-size: 12px;
    white-space: pre-wrap;
  }

  table {
    font-size: 12px;
  }
}
</style>
