<template>
  <span>
    <el-tag size="small" v-if="type == 0" type="info"> {{ content }}</el-tag>

    <el-tag v-else-if="type == 1" type="">
      {{ content }}
    </el-tag>
    <el-tag v-else-if="type == 2" type="" effect="plain">
      {{ content }}
    </el-tag>
  </span>
</template>
<script>
export default {
  props: {
    type: Number,
    content: Number,
  },
};
</script>
<style lang="less" scoped>
.el-tag {
  background: #1989fa;
  color: #fff;
}
.el-tag.el-tag--plain {
  border: 1px solid #1989fa;
  color: #1989fa;
  background: none;
}

.el-tag.el-tag--info.el-tag--small.el-tag--light {
  background-color: #fff;
  border-color: #e9e9eb;
  color: #909399;
}
</style>
